import { ApolloProvider } from '@apollo/client'
import { client } from '../../lib/apolloClient'
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { Cart } from '../../types/Cart'
import { AuthProvider } from './hooks/useAuth'

const defaultState: AppState = {
  setCart: () => {},
  cart: undefined,
}

const AppContext = createContext(defaultState)

const AppProvider: React.FC<ContextProps> = ({ children }) => {
  const [cart, setCart] = useState<AppState['cart']>(defaultState.cart)

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
      <AppContext.Provider value={{ cart, setCart }}>
        {children}
        </AppContext.Provider>
      </AuthProvider>
    </ApolloProvider>
  )
}

const useAppState = () => useContext(AppContext)

type AppState = {
  cart?: Cart
  setCart: Dispatch<SetStateAction<Cart | undefined>>
}

type ContextProps = {
    children: React.ReactNode;
}

export { AppProvider, useAppState }
