exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-acc-settings-tsx": () => import("./../../../src/pages/acc-settings.tsx" /* webpackChunkName: "component---src-pages-acc-settings-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-customer-agreement-tsx": () => import("./../../../src/pages/customer-agreement.tsx" /* webpackChunkName: "component---src-pages-customer-agreement-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-log-in-tsx": () => import("./../../../src/pages/log-in.tsx" /* webpackChunkName: "component---src-pages-log-in-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-shipping-return-tsx": () => import("./../../../src/pages/shipping&return.tsx" /* webpackChunkName: "component---src-pages-shipping-return-tsx" */),
  "component---src-pages-shop-by-brand-tsx": () => import("./../../../src/pages/shop-by-brand.tsx" /* webpackChunkName: "component---src-pages-shop-by-brand-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-ty-for-registration-tsx": () => import("./../../../src/pages/ty-for-registration.tsx" /* webpackChunkName: "component---src-pages-ty-for-registration-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-products-by-category-tsx": () => import("./../../../src/templates/productsByCategory.tsx" /* webpackChunkName: "component---src-templates-products-by-category-tsx" */)
}

